<template>
  <BaseIconTooltip :color="iconData.color"
                   :iconType="iconData.type"
                   :message="iconData.message"
  />
</template>

<script>
import BaseIconTooltip from './BaseIconTooltip';

export default {
  components: { BaseIconTooltip },
  props: {
    searchValue: Boolean,
  },
  computed: {
    iconData() {
      return this.searchValue
        ? {
            color: 'red',
            type: 'error',
            message: 'Address is marked in the blacklist',
          }
        : {
            color: 'green',
            type: 'check',
            message: 'Address is not in the blacklist',
          };
    },
  },
};
</script>

<style>
</style>
