<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" large :color="color">
        {{ iconType }}
      </v-icon>
    </template>
    <span> {{ message }} </span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'BaseIconTooltip',
  props: ['color', 'iconType', 'message'],
};
</script>

<style>
</style>
