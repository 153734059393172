<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items"
    >
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  props: {
    items: Array,
  },
  data() {
    return {
      headers: [
        {
          text: 'Source Id',
          value: 'sourceId',
        },
        {
          text: 'Address',
          value: 'address',
        },
        {
          text: 'Latitude',
          value: 'location[0].lat',
        },
        {
          text: 'Longitude',
          value: 'location[0].lon',
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>
