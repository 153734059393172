<template>
  <v-expansion-panels v-model="expanded">
    <v-expansion-panel v-if="Object.keys(computedAlerts).length > 0">
      <v-expansion-panel-header>
        <a href="#" style="font-size: 1rem"> {{ resultsPanelHeaderCaption }} </a>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container fluid>
          <v-row justify="start">
            <div v-if="computedAlerts.foundByGeoLocation === true">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <section v-on="on" v-bind="attrs" id="section_hover">
                    <v-icon large color="green">check</v-icon>
                    <span>Address is found in database. Hover for more information</span>
                  </section>
                </template>
                <section>
                  Name: {{ itemFoundByGeoLocation.name }}
                  <br />
                  Address: {{ itemFoundByGeoLocation.address }}
                </section>
              </v-tooltip>
            </div>
            <div v-else-if="computedAlerts.foundByGeoLocation === false">
              <v-icon large color="gray">highlight_off</v-icon>
              <span>Address isn't found in database</span>
            </div>
          </v-row>
          <v-row justify="start" class="mt-3">
            <div v-if="computedAlerts.foundByAddressBlackList === true">
              <v-icon large color="red">priority_high</v-icon>
              <span>Found in black list of addresses</span>
            </div>
            <div v-else-if="computedAlerts.foundByAddressBlackList === false">
              <v-icon large color="green">check</v-icon>
              <span>Cleared by black list of addresses</span>
            </div>
          </v-row>
          <v-row justify="start" class="mt-3">
            <div v-if="computedAlerts.foundByNegativeKeyWordList === true">
              <v-icon large color="red">priority_high</v-icon>
              <span>Found in list of negative keywords</span>
            </div>
            <div v-else-if="computedAlerts.foundByNegativeKeyWordList === false">
              <v-icon large color="green">check</v-icon>
              <span>Cleared by list of negative keywords</span>
            </div>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-else>
      <v-expansion-panel-header>
        <a href="#" style="font-size: 1rem">
          No additional address information available. Enter an address and click the check button again
        </a>
      </v-expansion-panel-header>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    items: Array,
  },
  data() {
    return {
      expanded: 0, // the index of the first expansion panel
    };
  },
  computed: {
    computedAlerts() {
      const alerts = {};

      const foundByGeoLocation = this.items
        .filter((item) => item.foundByGeoLocation !== undefined)
        .reduce((result, item) => result || item.foundByGeoLocation, undefined);

      if (foundByGeoLocation !== undefined) {
        alerts.foundByGeoLocation = foundByGeoLocation;
      }

      const { foundByAddressBlackList, foundByNegativeKeyWordList } = this.items[0];

      if (foundByAddressBlackList !== undefined) {
        alerts.foundByAddressBlackList = foundByAddressBlackList;
      }
      if (foundByNegativeKeyWordList !== undefined) {
        alerts.foundByNegativeKeyWordList = foundByNegativeKeyWordList;
      }

      return alerts;
    },
    resultsPanelHeaderCaption() {
      const action = this.expanded === 0 ? 'hide' : 'view';

      return `Click here to ${action} additional address information`;
    },
    itemFoundByGeoLocation() {
      const foundItem = this.items.filter((item) => item.foundByGeoLocation === true).shift();

      return {
        name: foundItem.foundNames[0].name,
        address: foundItem.information.locations?.[0].value || foundItem.information.genericAddress[0].value,
      };
    },
  },
};
</script>
