<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items">
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  props: {
    items: Array,
  },
  data() {
    return {
      headers: [
        {
          text: 'Matched word',
          value: 'matchedWord',
        },
        {
          text: 'Found in',
          value: 'foundIn',
        }],
    };
  },
};
</script>

<style scoped>

</style>
