import GeoLocationAlertIconTooltip from './alerts/GeoLocation';
import AddressBlacklistAlertIconTooltip from './alerts/AddressBlacklist';
import NegativeKeywordsAlertIconTooltip from './alerts/NegativeKeywords';
import ScoreIconTooltip from './info/Score';

export default {
  GeoLocationAlertIconTooltip,
  AddressBlacklistAlertIconTooltip,
  NegativeKeywordsAlertIconTooltip,
  ScoreIconTooltip,
};
