<template>
  <v-form @submit.prevent="$emit('submit', formData)">
    <v-container fluid>
      <v-row justify="center" class="mt-5">
        <v-col cols="5">
          <v-text-field
                label="Receiver name"
                v-model="formData.name"
                clearable
                solo
                full-width
          />
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="5">
          <v-text-field
                label="Address"
                v-model="formData.address"
                clearable
                solo
                full-width
                v-on:input="setAddressSwitchNullity"
          />
        </v-col>
        <!-- <v-col cols="2">
          <v-switch
                label="Search address in blacklist"
                color="primary"
                v-model="formData.searchAddressInBlacklist"
                :disabled="!formData.address"
        />
        </v-col> -->
      </v-row>

      <v-row justify="center">
        <v-col cols="3">
          <v-text-field
                label="Date of Birth"
                v-model="formData.dateOfBirth"
                clearable
                solo
                full-width
                :rules="[rules.date]"
          />
        </v-col>
        <!-- <v-col cols="3">
          <v-text-field
                label="Phone Number"
                v-model="formData.phone"
                clearable
                solo
                full-width
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
                label="Email"
                v-model="formData.email"
                clearable
                solo
                full-width
                :rules="[rules.email]"
          />
        </v-col> -->
      </v-row>

      <v-row justify="center">
        <v-col cols="2">
          <v-switch
                label="Search address in blacklist"
                color="primary"
                v-model="formData.searchAddressInBlacklist"
                :disabled="!formData.address"
        />
        </v-col>
        <v-col cols="2">
          <v-btn
                :disabled="!isCheckButtonEnabled"
                type="submit"
                large
                color="primary"
                class="mt-2"
          >
            Check
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { isValid as validateDate } from '@/utils/dates';
import { email as validateEmail } from '@/utils/validations';
import { SEARCH_RESET } from '@/store/actions/search';

const { mapActions } = createNamespacedHelpers('search');

export default {
  data() {
    return {
      formData: {
        name: null,
        dateOfBirth: null,
        address: null,
        email: null,
        phone: null,
        searchAddressInBlacklist: null,
      },
      rules: {
        date: () => this.isDateValid || 'Enter valid date - D/M/YYYY or YYYY',
        email: () => this.isEmailValid || 'Enter valid email',
      },
    };
  },
  computed: {
    isCheckButtonEnabled() {
      return (this.formData.name || this.formData.address) && this.isDateValid && this.isEmailValid;
    },
    isDateValid() {
      const { dateOfBirth } = this.formData;

      if (!dateOfBirth) {
        return true;
      }

      return validateDate(dateOfBirth, ['D/M/YYYY', 'YYYY']);
    },
    isEmailValid() {
      const { email } = this.formData;

      if (!email) {
        return true;
      }

      return validateEmail(email);
    },
  },
  methods: {
    setAddressSwitchNullity() {
      if (this.formData.address) {
        if (this.formData.searchAddressInBlacklist == null) {
          this.formData.searchAddressInBlacklist = false;
        }
      } else {
        this.formData.searchAddressInBlacklist = null;
      }
    },
    ...mapActions({ resetSearch: SEARCH_RESET }),
  },
  mounted() {
    this.resetSearch();
  },
};
</script>

<style>
</style>
