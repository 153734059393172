<template>
  <v-container fluid>
    <CRMSearchAlertsDisplay :items="items"/>
    <EntitiesList
      :items="filteredItems"
      :headers="computedHeaders"
      :item-data-slots="dataSlots"
      group-by="sourceId"
    >
      <template v-for="slot in dataSlots" v-slot:[`item.${slot}`]="{ slotProps }">
        <component :key="slot"
                   :is="slotValueToTooltipComponentMap[slot]"
                   :search-value="slotProps.value"
                   :search-item="slotProps.item"
        />
      </template>
    </EntitiesList>
  </v-container>
</template>

<script>
import EntitiesList from '@/components/entity/EntitiesList';
import CRMSearchAlertsDisplay from './CRMSearchAlertsDisplay';
import iconTooltips from './icon-tooltips';
import GeoLocationAlertIconTooltip from './icon-tooltips/alerts/GeoLocation';
import ScoreInfoTooltip from './icon-tooltips/info/Score';

export default {
  components: {
    CRMSearchAlertsDisplay,
    EntitiesList,
    ...iconTooltips,
  },
  props: {
    items: Array,
  },
  data() {
    return {
      slotValueToTooltipComponentMap: {
        foundByGeoLocation: GeoLocationAlertIconTooltip,
        postScore: ScoreInfoTooltip,
      },
      headers: [
        { text: 'Name', value: 'foundNames[0].name' },
      ],
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => {
        const { foundNames } = item;

        if (!foundNames || foundNames.length === 0) {
          return false;
        }
        if (!foundNames[0] || Object.keys(foundNames[0]).length === 0) {
          return false;
        }
        if (!item.sourceId) {
          return false;
        }

        return true;
      });
    },
    computedHeaders() {
      const combinedHeaders = [...this.headers];

      if (this.items.some((item) => item.foundByGeoLocation !== undefined)) {
        combinedHeaders.push({
          text: 'Address Vicinity',
          value: 'foundByGeoLocation',
          align: 'center',
        });
      }

      return combinedHeaders;
    },
    dataSlots() {
      const alertSlots = ['foundByGeoLocation'];
      const infoSlots = ['postScore'];

      return [...alertSlots, ...infoSlots];
    },
  },
};
</script>
