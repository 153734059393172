<template>
  <v-tooltip top color="#E0E0E0">
    <template v-slot:activator="{ on, attrs }">
      <v-chip medium v-on="on" v-bind="attrs">
        {{ round(searchValue) }}
      </v-chip>
    </template>
    <div>
      <section>
        <span style="color: black">
          Phonetic: {{ round(searchItem.scoreExplain.baseScore) }}
        </span>
      </section>

      <section v-for="metric in metrics" :key="metric">
        <span :style="{ color: isBoostValue(metric)? 'green' : 'red' }">
          {{ capitalize(metric) }}: {{ getScoreValue(metric) }}
        </span>
      </section>

      <section>
        <span style="color: black">
          Normalization factor: {{ round(searchItem.scoreExplain.normalizationFactor) }}
        </span>
      </section>
    </div>
  </v-tooltip>
</template>

<script>
import { capitalize } from 'lodash';

export default {
  name: 'ScoreInfoTooltip',
  props: {
    searchValue: Number,
    searchItem: Object,
  },
  computed: {
    metrics() {
      return ['address', 'date', 'phone', 'email'].filter(this.getScoreValue);
    },
  },
  methods: {
    capitalize,
    round(value) {
      return Math.round(value * 100) / 100;
    },
    getScoreValue(metric) {
      const { scoreExplain } = this.searchItem;

      return scoreExplain[`${metric}Boost`] || scoreExplain[`${metric}DeBoost`];
    },
    isBoostValue(metric) {
      return this.getScoreValue(metric) > 0;
    },
  },
};
</script>
