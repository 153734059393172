import { render, staticRenderFns } from "./CRMSearch.vue?vue&type=template&id=f40fcbd8&scoped=true&"
import script from "./CRMSearch.vue?vue&type=script&lang=js&"
export * from "./CRMSearch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f40fcbd8",
  null
  
)

export default component.exports