<template>
  <v-container fluid>
    <LoadIndicator v-model="isLoading" />
    <CRMSearchForm @submit="doSearch" />
    <CRMSearchResultsContainer :results="searchResult" :has-loaded-once="hasLoadedOnce" />
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { createCRMSearchQuery } from '@/helpers/search';
import CRMSearchForm from '@/components/crm/CRMSearchForm';
import CRMSearchResultsContainer from '@/components/crm/CRMSearchResultsContainer';
import LoadIndicator from '@/components/common/LoadIndicator';
import { GEO_SEARCH_REQUEST } from '@/store/actions/search';

const { mapActions, mapGetters } = createNamespacedHelpers('search');

export default {
  components: {
    CRMSearchForm,
    CRMSearchResultsContainer,
    LoadIndicator,
  },
  computed: {
    ...mapGetters(['searchResult', 'hasLoadedOnce', 'isLoading']),
  },
  methods: {
    ...mapActions({
      searchRequest: GEO_SEARCH_REQUEST,
    }),

    async doSearch(formData) {
      const query = createCRMSearchQuery(formData);

      await this.searchRequest(query);
    },
  },
};
</script>

<style scoped>

</style>
