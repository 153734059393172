<template>
  <v-container fluid>
    <CRMSearchResults v-if="results.length > 0" :items="results" />
    <NoResultsMessage v-else-if="results.length === 0 && hasLoadedOnce" />
  </v-container>
</template>

<script>
import NoResultsMessage from '@/components/common/NoResultsMessage';
import CRMSearchResults from './CRMSearchResults';

export default {
  components: {
     CRMSearchResults,
     NoResultsMessage,
  },
  props: {
    results: Array,
    hasLoadedOnce: Boolean,
  },
};
</script>

<style scoped>
</style>
