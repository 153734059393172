<template>
  <v-container fluid>
    <LoadIndicator v-model="isLoading"/>

    <v-form @submit.prevent="doSearch">
      <v-row>
        <v-col cols="4">
          <v-text-field label="Receiver name"
                        ref="searchBox"
                        v-model="receiverName"
                        clearable
                        solo
                        full-width
                        class="pl-md-10 pr-md-10"

          />
        </v-col>
        <v-col>
          <v-text-field label="Address" v-model="address" clearable solo full-width/>
        </v-col>
        <v-col cols="2">
          <v-btn :disabled="!(receiverName&&address)" type="submit" large color="primary" class="mt-2">Check</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <MultiCheckResults
      :aml-results="amlResults"
      :geo-results="geoResults"
      :keyword-results="keywordsResults"
      :has-loaded-once="hasLoadedOnce"
    >
    </MultiCheckResults>

  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import MultiCheckResults from '@/components/address/MultiCheckResults';
import LoadIndicator from '@/components/common/LoadIndicator';
import { SEARCH_REQUEST, SEARCH_RESET } from '@/store/actions/geocheck';

const { mapGetters, mapActions } = createNamespacedHelpers('geocheck');

export default {
  name: 'AddressSearch',
  components: {
    MultiCheckResults,
    LoadIndicator,
  },

  data() {
    return {
      receiverName: null,
      address: null,
      amlQueryParams: {
        isIndividual: true,
        isCompany: true,
        isInitials: false,
        queryString: '',
        selectedDataSources: [],
        yearsOfBirth: [],
        countries: [],
        threshold: 0.4,
        mlThreshold: 0.7,
      },
    };
  },
  computed: {
    ...mapGetters(['amlResults', 'geoResults', 'keywordsResults', 'hasLoadedOnce', 'isLoading']),
  },
  methods: {
    ...mapActions({ search: SEARCH_REQUEST, resetSearch: SEARCH_RESET }),
    async doSearch() {
      await this.search({
        receiverName: this.receiverName,
        amlQueryParams: this.amlQueryParams,
        address: this.address,
      });
    },
  },
  async mounted() {
    await this.resetSearch();
  },
};
</script>

<style scoped>

</style>
