<template>
  <v-container fluid>
    <v-row v-if="hasLoadedOnce && areResultsEmpty()">
      <v-col>
        <NoResultsMessage />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-expansion-panels multiple>
        <v-expansion-panel v-if="amlResults.length">
          <v-expansion-panel-header>
            {{ amlResults.length }} screened names were found. Click for details
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <NameCheckResults :items="amlResults"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="geoResults.length">
          <v-expansion-panel-header>{{ geoResults.length }} screened addresses were found. Click for details:
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <AddressCheckResults :items="geoResults"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="keywordResults.length">
          <v-expansion-panel-header>{{ keywordResults.length }} negative keywords were found. Click for details:
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <NegativeCheckResults :items="keywordResults"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import AddressCheckResults from '@/components/address/AddressCheckResults';
import NegativeCheckResults from '@/components/address/NegativeCheckResults';
import NameCheckResults from '@/components/address/NameCheckResults';
import NoResultsMessage from '@/components/common/NoResultsMessage';

export default {
  components: {
    NameCheckResults,
    AddressCheckResults,
    NegativeCheckResults,
    NoResultsMessage,
  },
  props: {
    amlResults: Array,
    geoResults: Array,
    keywordResults: Array,
    hasLoadedOnce: Boolean,
  },
  methods: {
    areResultsEmpty() {
      return this.amlResults?.length === 0 && this.geoResults?.length === 0 && this.keywordResults?.length === 0;
    },
  },
};
</script>
