<template>
  <v-container fluid>
    <AddressSearch>
    </AddressSearch>
  </v-container>

</template>

<script>
import AddressSearch from '@/components/address/MultiCheck';

export default {
  name: 'AddressCheck',
  components: { AddressSearch },
};

</script>

<style scoped>

</style>
